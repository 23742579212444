<template>
  <div
    class="single-preview preview-summarize-written-text"
  >
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>

      <div class="subcontent">
        <div>
          <p class="text-left">
            {{ record.data.question }}
          </p>
        </div>
      </div>

      <div
        class="subcontent br-resp mt-20"
        v-if="isAnalyze"
      >
        <h3 class="text-center"><app-i18n code="entities.examResult.questionPreview.yourResponse"></app-i18n></h3>
        <div>
          <p class="text-left">
            {{ data.answer.input }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'summarizeWrittenText',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="single-preview preview-reorder-paragraph">
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead bold">{{ description }}</div>

      <div class="subcontent-details subcontent">
        <div
          class="subcontent-drag-options subcontent-options"
        >
          <ul class="clear-ul">
            <li
              :key="index"
              v-for="(option, index) in record.data.options"
              class="text-left"
            >
              {{ option }}
            </li>
          </ul>
        </div>
      </div>

      <div
        v-if="isAnalyze"
        class="subcontent-details subcontent"
      >
        <h2><app-i18n code="entities.examResult.questionPreview.yourResponse"></app-i18n></h2>
        <div
          class="subcontent-drag-options subcontent-options"
        >
          <ul
            class="clear-ul"
            v-if="data.answer && data.answer.inputs"
          >
            <li
              :key="index"
              v-for="(option, index) in data.answer.inputs"
              class="text-left"
            >
              {{ option }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'reorderParagraph',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

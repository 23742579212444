var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-preview preview-single-option"},[_c('div',{class:'analyze-' + _vm.isAnalyze},[_c('div',{staticClass:"subhead"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"subcontent"},[_c('div',[_c('p',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.record.data.question)+" ")])])]),_c('div',{staticClass:"subcontent-details"},[_c('h3',[_vm._v(" "+_vm._s(_vm.record.data.subtitle)+" ")]),_c('div',{staticClass:"subcontent-options"},[_c('ul',_vm._l((_vm.record.data.options),function(option,index){return _c('li',{key:index,class:{
              active:
                _vm.record.answer &&
                _vm.record.answer.input &&
                option.includes(_vm.record.answer.input), //dogru cevap mi
              response:
                _vm.isAnalyze &&
                _vm.data.answer &&
                _vm.data.answer.input &&
                option.includes(_vm.data.answer.input), //cocugun verdigi cevap dogru ise
            }},[_vm._v(" "+_vm._s(option)+" ")])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-preview preview-selected-fill-in-the-blanks"},[_c('div',{class:'analyze-' + _vm.isAnalyze},[_c('div',{staticClass:"subhead"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"subcontent subcontent-fill-in-the-blanks"},[_c('div',[_c('p',{staticClass:"text-left lh2"},_vm._l((_vm.options),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item)+" "),(index < _vm.count - 1)?_c('select',{staticClass:"select-options"},_vm._l((_vm.record
                    .data.options[index]),function(option,indexOption){return _c('option',{key:indexOption,domProps:{"value":indexOption}},[_vm._v(_vm._s(option))])}),0):_vm._e()])}),0)])]),(
          _vm.isAnalyze && _vm.data.answer && _vm.data.answer.inputs
        )?_c('div',{staticClass:"subcontent subcontent-fill-in-the-blanks"},[_c('h3',[_c('app-i18n',{attrs:{"code":"entities.examResult.questionPreview.yourResponse"}})],1),_c('div',[_c('p',{staticClass:"text-left lh2"},_vm._l((_vm.options),function(item2,index2){return _c('span',{key:index2 * 10},[_vm._v(" "+_vm._s(item2)+" "),(index2 < _vm.count - 1)?_c('span',{class:{
                  lined: true,
                  liCorrect:
                    _vm.record.data.options.length > 0 &&
                    _vm.record.data.options[index2][0] ===
                      _vm.data.answer.inputs[index2],
                  liWrong:
                    _vm.record.data.options.length > 0 &&
                    _vm.record.data.options[index2][0] !==
                      _vm.data.answer.inputs[index2],
                },attrs:{"id":index2}},[_vm._v(_vm._s(_vm.data.answer.inputs[index2]))]):_vm._e()])}),0)])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
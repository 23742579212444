<template>
  <div
    class="single-preview preview-highlight-incorrent-words"
  >
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>

      <div class="pre-tool">
        <div class="tool-top">
          <i
            class="text-contrast raw-icon el-icon-fa-volume-up"
          ></i>
          <span><app-i18n code="entities.examResult.questionPreview.listening"></app-i18n></span>
        </div>

        <div class="tool-src mt-10">
          <audio controls :src="record.data.src"></audio>
        </div>
      </div>

      <div class="subcontent lined-options">
        <div v-if="correctAnswers">
          <p>
            <span
                    :key="sIndex"
                    v-for="(sentence, sIndex) in sentences"
            >
              <span :key="wIndex"
                    v-for="(word,wIndex) in sentence.split(' ')"
                    v-bind:class="getClassesForWord(word)"
              >
                {{ word }}
              </span>
              <span v-if="sIndex < (sentences.length - 1)" class="lined" v-bind:class="{
                  liHighlightCorrect: studentAnswers && studentAnswers.includes(correctAnswers[sIndex])}">{{
                correctAnswers[sIndex] || "-"
              }}</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'highlightIncorrectWords',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      studentAnswers: null,
      correctAnswers: null
    };
  },
  mounted() {
    this.prepareData();
  },
  computed: {
    sentences() {
      return this.record.answer.editor.split('$$');
    },
  },
  methods: {
    prepareData(){
      const regex = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
      if(this.data && this.data.answer){
        this.studentAnswers = this.data.answer.inputs.map(i => i.replace("\n","").replace(regex,"").trim());
      }
      this.correctAnswers = this.record.answer.optionsAnswer.map(i => i.replace("\n","").replace(regex,"").trim());
    },
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
    getClassesForWord(word){
      if(this.studentAnswers && this.studentAnswers.includes(word)){
        return {lined: true, liHighlightWrong:true, wrongBG:true }
      }
      return {};
    }
  },
};
</script>

<style scoped></style>

<template>
  <div
    class="single-preview preview-highlight-correct-summary"
  >
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead bold">
        {{ description }}
      </div>
      <div class="pre-tool">
        <div class="tool-top ">
          <i
            class="text-contrast raw-icon el-icon-fa-volume-up"
          ></i>
          <span><app-i18n code="entities.examResult.questionPreview.listening"></app-i18n></span>
        </div>

        <div class="tool-src mt-10">
          <audio controls :src="record.data.src"></audio>
        </div>
      </div>
      <div class="subcontent highlight-options">
        <div
          :key="index"
          v-for="(option, index) in record.data.options"
          v-bind:class="{
            active: option.includes(record.answer.input),
            response:
              data &&
              data.answer.input &&
              option.includes(data.answer.input)
          }"
        >
          {{ option }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'highlightCorrectSummary',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="single-preview preview-summarize-spoken-text">
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>
      <div class="pre-tool">
        <div class="tool-top">
          <i
            class="text-contrast raw-icon el-icon-fa-volume-up"
          ></i>
          <span><app-i18n code="entities.examResult.questionPreview.listening"></app-i18n></span>
        </div>

        <div class="tool-src mt-10">
          <audio controls :src="record.data.src"></audio>
        </div>
      </div>
      <div
        class="subcontent br-resp mt-20"
        v-if="isAnalyze"
      >
        <h3 class="text-center"><app-i18n code="entities.examResult.questionPreview.yourResponse"></app-i18n></h3>
        <div>
          <p class="text-left" v-if="data.answer && data.answer.input">
            {{ data.answer.input }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'summarizeSpokenText',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

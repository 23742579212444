<template>
  <div class="single-preview preview-read-aloud">
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>
<!--      <div class="pre-tool">-->
<!--        <div class="tool-top">-->
<!--          <i-->
<!--            class="text-contrast raw-icon el-icon-fa-edit"-->
<!--          ></i>-->
<!--          <span>Fill In The Blanks</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="subcontent lined-options">
        <div>
          <p>
            <span
              :key="index"
              v-for="(item, index) in options"
            >
              {{ item }}
              <span v-if="index < count" class="lined">{{
                record.answer.optionsAnswer[index].trim()
              }}</span>
            </span>
          </p>
        </div>
      </div>
      <div class="all-options">
        <div :key="index" v-for="(item, index) in record.data.options">
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="subcontent lined-options"  v-if="isAnalyze && data.answer && data.answer.inputs">
        <div>
          <h3><app-i18n code="entities.examResult.questionPreview.yourResponse"></app-i18n></h3>
          <p>
            <span
                    :key="index"
                    v-for="(item, index) in options"
            >
              {{ item }}
              <span v-if="index < count" class="lined" v-bind:class="{
                  liCorrect: record.answer.optionsAnswer[index] === data.answer.inputs[index],
                  liWrong: record.answer.optionsAnswer[index] !== data.answer.inputs[index]}">{{
                data.answer.inputs[index] || "-"
              }}</span>
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'fillInTheBlanks',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
    count() {
      return this.record.data.question.split('$$').length - 1;
    },
    options() {
      return this.record.data.question.split('$$');
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

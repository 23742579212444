import { render, staticRenderFns } from "./summarizeWrittenText.vue?vue&type=template&id=5e2e1eb0&scoped=true&"
import script from "./summarizeWrittenText.vue?vue&type=script&lang=js&"
export * from "./summarizeWrittenText.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2e1eb0",
  null
  
)

export default component.exports
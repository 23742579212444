var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-preview preview-select-missing-word"},[_c('div',{class:'analyze-' + _vm.isAnalyze},[_c('div',{staticClass:"subhead"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"pre-tool"},[_c('div',{staticClass:"tool-top "},[_c('i',{staticClass:"text-contrast raw-icon el-icon-fa-volume-up"}),_c('span',[_c('app-i18n',{attrs:{"code":"entities.examResult.questionPreview.listening"}})],1)]),_c('div',{staticClass:"tool-src mt-10"},[_c('audio',{attrs:{"controls":"","src":_vm.record.data.src}})])]),_c('div',{staticClass:"subcontent-details"},[_c('div',{staticClass:"subcontent-options"},[_c('ul',_vm._l((_vm.record.data.options),function(option,index){return _c('li',{key:index,class:{
              active: _vm.record.answer &&
                _vm.record.answer.input &&
                option.includes(_vm.record.answer.input),
              response:
                _vm.isAnalyze &&
                _vm.data.answer &&
                _vm.data.answer.input &&
                option.includes(_vm.data.answer.input),
            }},[_vm._v(" "+_vm._s(option)+" ")])}),0)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }
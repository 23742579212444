var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"single-preview"},[_c('div',{class:'analyze-' + _vm.isAnalyze},[_c('div',{staticClass:"subhead"},[_vm._v(" "+_vm._s(_vm.description)+" ")]),_c('div',{staticClass:"pre-tool"},[_c('div',{staticClass:"tool-top"},[_c('i',{staticClass:"text-contrast raw-icon el-icon-fa-volume-up"}),_c('span',[_c('app-i18n',{attrs:{"code":"entities.examResult.questionPreview.listening"}})],1)]),_c('div',{staticClass:"tool-src mt-10"},[_c('audio',{attrs:{"controls":"","src":_vm.record.data.src}})])]),_c('div',{staticClass:"subcontent"},[_c('div',{staticClass:"text-left lh-2"},_vm._l((_vm.options),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item)+" "),(index < _vm.count)?_c('span',{staticClass:"correct-fill"},[_vm._v(_vm._s(_vm.record.answer.optionsAnswer[index]))]):_vm._e()])}),0)]),(
        _vm.isAnalyze && _vm.data.answer && _vm.data.answer.inputs
      )?_c('div',{staticClass:"subcontent "},[_c('h2',[_c('app-i18n',{attrs:{"code":"entities.examResult.questionPreview.yourResponse"}})],1),_c('div',{staticClass:"text-left lh-2"},_vm._l((_vm.options),function(item,index){return _c('span',{key:index},[_vm._v(" "+_vm._s(item)+" "),(index < _vm.data.answer.inputs.length)?_c('span',{class:{
              lined: true,
              liCorrect:
                _vm.record.answer.optionsAnswer[index] ===
                _vm.data.answer.inputs[index],
              liWrong:
                _vm.record.answer.optionsAnswer[index] !==
                _vm.data.answer.inputs[index],
            }},[_vm._v(_vm._s(_vm.data.answer.inputs[index] || "-"))]):_vm._e()])}),0)]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }
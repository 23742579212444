<template>
  <div class="single-preview preview-single-option">
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>

      <div class="subcontent">
        <div>
          <p class="text-left">
            {{ record.data.question }}
          </p>
        </div>
      </div>
      <div class="subcontent-details">
        <h3>
          {{ record.data.subtitle }}
        </h3>
        <div class="subcontent-options">
          <ul>
            <li
              :key="index"
              v-for="(option, index) in record.data.options"
              v-bind:class="{
                active:
                  record.answer &&
                  record.answer.input &&
                  option.includes(record.answer.input), //dogru cevap mi
                response:
                  isAnalyze &&
                  data.answer &&
                  data.answer.input &&
                  option.includes(data.answer.input), //cocugun verdigi cevap dogru ise
              }"
            >
              {{ option }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'singleOption',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

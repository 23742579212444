<template>
  <div
    class="single-preview preview-selected-fill-in-the-blanks"
  >
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>
<!--      <div class="pre-tool">-->
<!--        <div class="tool-top">-->
<!--          <i-->
<!--            class="text-contrast raw-icon el-icon-fa-mouse-pointer"-->
<!--          ></i>-->
<!--          <span>Selected Fill In The Blanks</span>-->
<!--        </div>-->
<!--      </div>-->
      <div class="subcontent subcontent-fill-in-the-blanks">
        <div>
          <p class="text-left lh2">
            <span
              :key="index"
              v-for="(item, index) in options"
            >
              {{ item }}
              <select
                v-if="index < count - 1"
                class="select-options"
              >
                <option
                  :key="indexOption"
                  v-for="(option, indexOption) in record
                    .data.options[index]"
                  :value="indexOption"
                  >{{ option }}</option
                >
              </select>
            </span>
          </p>
        </div>
      </div>
      <div
        class="subcontent subcontent-fill-in-the-blanks"
        v-if="
          isAnalyze && data.answer && data.answer.inputs
        "
      >
        <h3><app-i18n code="entities.examResult.questionPreview.yourResponse"></app-i18n></h3>
        <div>
          <p class="text-left lh2">
            <span
              :key="index2 * 10"
              v-for="(item2, index2) in options"
            >
              {{ item2 }}
              <span
                :id="index2"
                v-if="index2 < count - 1"
                v-bind:class="{
                  lined: true,
                  liCorrect:
                    record.data.options.length > 0 &&
                    record.data.options[index2][0] ===
                      data.answer.inputs[index2],
                  liWrong:
                    record.data.options.length > 0 &&
                    record.data.options[index2][0] !==
                      data.answer.inputs[index2],
                }"
                >{{ data.answer.inputs[index2] }}</span
              >
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'selectedFillInTheBlanks',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
    count() {
      return this.record.data.question.split('$$').length;
    },
    options() {
      return this.record.data.question.split('$$');
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

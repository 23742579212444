<template>
  <div class="single-preview preview-repeat-sentence">
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">{{ description }}</div>
      <div class="pre-tool">
        <div class="tool-top">
          <i
            class="text-contrast raw-icon el-icon-fa-microphone"
          ></i>
          <span><app-i18n code="entities.examResult.questionPreview.audioRecorder"></app-i18n></span>
        </div>

        <div class="tool-src mt-10">
          <audio controls :src="record.data.src"></audio>
        </div>
      </div>
      <div class="i-response mt-20" v-if="isAnalyze">
        <div class="audio-box resp-box">
          <h3 class="text-center"><app-i18n code="entities.examResult.questionPreview.yourResponse"></app-i18n></h3>
          <div class="audio-resp">
            <audio :src="data.answer.path" controls />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'repeatSentence',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

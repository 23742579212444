<template>
  <div class="single-preview">
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>
      <div class="pre-tool">
        <div class="tool-top">
          <i
            class="text-contrast raw-icon el-icon-fa-volume-up"
          ></i>
          <span><app-i18n code="entities.examResult.questionPreview.listening"></app-i18n></span>
        </div>

        <div class="tool-src mt-10">
          <audio controls :src="record.data.src"></audio>
        </div>
      </div>
      <div class="subcontent">
        <div class="text-left lh-2">
          <span
            :key="index"
            v-for="(item, index) in options"
          >
            {{ item }}
            <span
              v-if="index < count"
              class="correct-fill"
              >{{
                record.answer.optionsAnswer[index]
              }}</span
            >
          </span>
        </div>
      </div>
      <div
        v-if="
          isAnalyze && data.answer && data.answer.inputs
        "
        class="subcontent "
      >
        <h2><app-i18n code="entities.examResult.questionPreview.yourResponse"></app-i18n></h2>
        <div class="text-left lh-2">
          <span
            :key="index"
            v-for="(item, index) in options"
          >
            {{ item }}
            <span
              v-if="index < data.answer.inputs.length"
              v-bind:class="{
                lined: true,
                liCorrect:
                  record.answer.optionsAnswer[index] ===
                  data.answer.inputs[index],
                liWrong:
                  record.answer.optionsAnswer[index] !==
                  data.answer.inputs[index],
              }"
              >{{ data.answer.inputs[index] || "-" }}</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'writeInTheBlanks',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
    options() {
      return this.record.data.question.split('$$');
    },
    count() {
      return this.record.answer.optionsAnswer.length;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>

<template>
  <div class="single-preview preview-single-answer">
    <div :class="'analyze-' + isAnalyze">
      <div class="subhead">
        {{ description }}
      </div>

      <div class="pre-tool">
        <div class="tool-top ">
          <i
            class="text-contrast raw-icon el-icon-fa-volume-up"
          ></i>
          <span><app-i18n code="entities.examResult.questionPreview.listening"></app-i18n></span>
        </div>

        <div class="tool-src mt-10">
          <audio controls :src="record.data.src"></audio>
        </div>
      </div>
      <div class="subcontent-details">
        <h3>
          Which of the following most accurately summarizes
          the opinion of the author in the text?
        </h3>
        <div class="subcontent-options">
          <ul>
            <li
              :key="index"
              v-for="(option, index) in record.data.options"
              v-bind:class="{
                active: record.answer &&
                  record.answer.input &&
                  option.includes(record.answer.input),
                response:
                  isAnalyze &&
                  data.answer &&
                  data.answer.input &&
                  option.includes(data.answer.input),
              }"
            >
              {{ option }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { QuestionModel } from '@/modules/question/question-model';

export default {
  name: 'singleAnswer',
  props: {
    record: {
      type: Object,
      required: true,
    },
    isAnalyze: {
      type: Boolean,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
    },
  },
  computed: {
    inputs() {
      return 3;
    },
  },
  methods: {
    presenter(record, fieldName) {
      return QuestionModel.presenter(record, fieldName);
    },
  },
};
</script>

<style scoped></style>
